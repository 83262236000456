import React, { useState, useEffect } from "react"
import "@stripe/stripe-js"

import Checkout from "../components/checkout/checkout-step"
import { MedusaCheckoutProvider } from "../components/medusa-checkout-builder"

const CheckoutPage = () => {
  const [cartId, setCartId] = useState(null)

  useEffect(() => {
    const cachedCart = localStorage.getItem("medusa::cache")
    const cart = JSON.parse(cachedCart)
    if (cart) {
      setCartId(cart.id)
    }
  }, [])

  return (
    <MedusaCheckoutProvider cartId={cartId}>
      <Checkout />
    </MedusaCheckoutProvider>
  )
}

export default CheckoutPage
