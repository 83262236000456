/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import { loadStripe } from "@stripe/stripe-js"
const STRIPE_API_KEY =
  process.env.GATSBY_STRIPE_API_KEY ||
  "pk_test_51H0kStGHEg72qkSC8p7b2m537hXJQP3aKowX1aPVC3LeD3dMWL8nKQWeub5bTFUqiZ3wZerVfh2OY23z78jEQ3A400U8cGabd0"
let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(STRIPE_API_KEY)
  }
  return stripePromise
}
export default getStripe
