import styled from "@emotion/styled"
import React from "react"
import { Flex } from "theme-ui"
import Text from "../text/text"

const CheckboxContainer = styled.input`
  appearance: none;

  border: ${(props) => props.theme.borders.darkGreen};
  background-color: ${(props) => props.theme.colors.background};

  width: 40px;
  height: 20px;

  aspect-ratio: 1;

  :checked {
    background-color: ${(props) => props.theme.colors.darkGreen};

    :after {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-top: 4px;
      content: "✓";
      color: ${(props) => props.theme.colors.white};
    }
  }
`

const SquareCheckboxContainer = styled.input`
  appearance: none;

  // border: ${(props) => props.theme.borders.darkGreen};
  background-color: ${(props) => props.theme.colors.background};

  width: 30px;
  height: 30px;

  aspect-ratio: 1;

  :checked {
    background-color: ${(props) => props.theme.colors.darkGreen};

    :after {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-top: 4px;
      content: "✓";
      color: ${(props) => props.theme.colors.white};
    }
  }
`

const Checkbox = ({
  value,
  onClick,
  label,
  labelSize = "14px",
  disabled = false,
  isSquare = false,
}) => {
  return (
    <Flex sx={{ alignItems: ["flex-start", "center"] }}>
      {isSquare ? (
        <SquareCheckboxContainer
          value={value}
          checked={value}
          disabled={disabled}
          type="checkbox"
          onClick={onClick}
        />
      ) : (
        <CheckboxContainer value={value} type="checkbox" onClick={onClick} />
      )}
      <Text
        sx={{
          marginLeft: 1,
          paddingTop: [0, "4px"],
          fontSize: labelSize,
          maxWidth: ["350px", "700px"],
        }}
      >
        {label}
      </Text>
    </Flex>
  )
}

export default Checkbox
