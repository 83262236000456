import * as Yup from "yup"

export const Schema = Yup.object().shape({
  email: Yup.string().trim().email("Invalid email").required("Required!"),
  shipping_address: Yup.object().shape({
    first_name: Yup.string()
      .trim()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    last_name: Yup.string()
      .trim()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    address_1: Yup.string()
      .trim()
      .required("Required!")
      .max(45, "Limit on 45 characters"),
    address_2: Yup.string()
      .trim()
      .nullable(true)
      .max(45, "Limit on 45 characters"),
    country_code: Yup.string().trim().required("Required!"),
    city: Yup.string().trim().required("Required!"),
    postal_code: Yup.string().trim().required("Required!"),
    province: Yup.string().nullable(true),
    phone: Yup.string().trim().nullable(true),
  }),
  billing_address: Yup.object().shape({
    first_name: Yup.string()
      .trim()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    last_name: Yup.string()
      .trim()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    address_1: Yup.string()
      .trim()
      .required("Required!")
      .max(45, "Limit on 45 characters"),
    address_2: Yup.string()
      .trim()
      .nullable(true)
      .max(45, "Limit on 45 characters"),
    country_code: Yup.string().trim().required("Required!"),
    city: Yup.string().trim().required("Required!"),
    postal_code: Yup.string().trim().required("Required!"),
    province: Yup.string().nullable(true),
    phone: Yup.string().trim().nullable(true),
  }),
})
