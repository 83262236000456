import styled from "@emotion/styled"
import { Formik } from "formik"
import { navigate } from "gatsby-link"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { Box, Flex } from "theme-ui"
import StoreContext from "../../context/store-context"
import useWindowSize from "../../hooks/use-window-size"
import useNotifications from "../../hooks/useNotifications"
import {
  trackCheckoutStepCompleted,
  trackCheckoutStepViewed,
  trackPurchase,
} from "../../services/analytics"
import Medusa from "../../services/api"
import checkInventory from "../../util/check-inventory"
import { createClient } from "../../util/client"
import { MobileOnly } from "../base"
import Spinner from "../base/spinner"
import Text from "../base/text/text"
import Cart from "../cart"
import CartDrawer from "../cart-drawer"
import CartTotals from "../cart/cart-totals"
import { useMedusaCheckout } from "../medusa-checkout-builder"
import PaymentStep from "./payment-step"
import ShippingStep from "./shipping-step"
import UserInformation, { setCartFromObject } from "./user-information"
import { Schema } from "./user-information-schema"

const Container = styled(Flex)`
  flex-direction: column;
  margin: ${(props) => props.theme.space[1]};
  align-items: center;

  flex-grow: 1;
`

const StepContainer = styled(Flex)`
  width: 100%;

  ${(props) => props.theme.bp.desktop} {
    max-width: 1200px;
  }
`

const Checkout = () => {
  const [newsletterConsent, setNewsletterConsent] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [processing, setProcessing] = useState(false)
  const [canPay, setCanPay] = useState(false)
  const { width } = useWindowSize()

  const client = createClient()

  const {
    cart,
    update,
    addShippingMethod,
    updateLineItem,
    hasShipping,
    hasShippingAddress,
    hasEmail,
    processingDetails,
    processingShipping,
    removeLineItem,
    isLoading,
  } = useMedusaCheckout()

  const {
    updateLineItem: updateLineStoreContext,
    removeLineItem: removeLineStoreContext,
    cart: storeCart,
  } = useContext(StoreContext)

  const { pushNotification } = useNotifications()

  useEffect(() => {
    trackCheckoutStepViewed(cart, currentStep)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  /**
   * Ensure inventory
   */
  const ensureInventory = async () => {
    const result = await checkInventory(storeCart)

    if (result.didUpdate) {
      for (const i of result.itemsToDelete) {
        await removeLineItem(i.id)
        await removeLineStoreContext(i.id)
      }

      for (const i of result.itemsToReduce) {
        await updateLineItem(i.id, i.quantity)
        await updateLineStoreContext(i.id, i.quantity)
      }
    }

    return result
  }

  /**
   * Updates that cart with the provided address and email
   */
  const handleShippingSubmit = async (address, email, billingAddress) => {
    await ensureInventory()

    const country = cart.region.countries.find(
      (c) => c.display_name === address.country_code
    )

    // Enforce state in US and Canada for shipping provider
    if (address.country_code === ("us" || "ca") && !address.province) {
      return
    }

    setCurrentStep(2)
    trackCheckoutStepCompleted(cart, 1)

    const billing_address = billingAddress || address

    return update({
      email,
      shipping_address: { ...address, country_code: country.iso_2 },
      billing_address: { ...billing_address, country_code: country.iso_2 },
    })
  }

  /**
   * Updates the cart's shipping methods.
   */
  const handleDeliverySubmit = async (options) => {
    await ensureInventory()

    const selected = Object.keys(options).map((k) => options[k])
    await Promise.all(
      selected.map((option) => {
        return addShippingMethod({
          option_id: option.id,
          data: option.data,
        })
      })
    )
    setCurrentStep(3)
    trackCheckoutStepCompleted(cart, 2)
  }

  const completeOrder = useCallback(async () => {
    let order = await client.carts.complete(cart.id)

    if (order) {
      let params = []

      if ("data" in order) {
        order = order.data
      }

      params.push(`o=${order.id}`)

      trackPurchase(order)
      navigate(`/done?${params.join("&")}`, {
        replace: true,
      })
    }
  }, [client.carts, client.orders])

  /**
   * If payment is completed by the underlying component in payment state
   * go straight to the payment site
   */
  const handlePaymentCompleted = async () => {
    trackCheckoutStepCompleted(cart, 3)

    if (newsletterConsent) {
      Medusa.newsletter.signup({ email: cart.email, ids: {} }).catch(() =>
        pushNotification({
          id: "newsletter-failed",
          message:
            "Newsletter sign up failed. You can also sign up at the bottom of the page",
        })
      )
    }

    await completeOrder()
  }

  useEffect(() => {
    if (hasEmail && hasShipping && hasShippingAddress) {
      setCanPay(true)
    }
  }, [hasEmail, hasShipping, hasShippingAddress])

  const handleStepContainer = () => (
    <>
      <StepContainer key="checkout-user-information">
        <Box
          sx={{
            flex: "1",
            width: ["80%", "60%"],
            opacity: isLoading || !processing ? 1 : 0.3,
          }}
        >
          <Text mb={[2, 4]}>Your details</Text>
          <Formik
            initialValues={setCartFromObject({
              shipping_address: cart.shipping_address,
              billing_address: cart.billing_address,
              email: cart.email,
              country: cart.region.countries.find(
                (country) =>
                  country.iso_2 === cart.shipping_address.country_code
              )?.display_name,
            })}
            validationSchema={Schema}
            onSubmit={() => {}}
            enableReinitialize={true}
          >
            <UserInformation
              isProcessing={processingDetails || !cart.id}
              regionId={cart?.region?.id}
              newsletterConsent={newsletterConsent}
              setNewsletterConsent={setNewsletterConsent}
              handleSubmit={(submittedAddr, submittedEmail, billingAddress) =>
                handleShippingSubmit(
                  submittedAddr,
                  submittedEmail,
                  billingAddress
                )
              }
            />
          </Formik>
        </Box>
      </StepContainer>
      <StepContainer sx={{ flexDirection: "column" }}>
        <Flex sx={{ width: "100%" }}>
          <ShippingStep
            isProcessing={processingShipping || processing || isLoading}
            settingShipping={processingShipping}
            cart={cart}
            handleDeliverySubmit={handleDeliverySubmit}
            savedMethods={cart.shipping_methods}
          />
        </Flex>
      </StepContainer>
      <StepContainer
        key="checkout-payment"
        sx={{ flexDirection: "column", alignItems: "center" }}
      >
        <Box
          sx={{
            width: "100%",
            opacity:
              (isLoading || !processing) &&
              hasEmail &&
              hasShipping &&
              hasShippingAddress
                ? 1
                : 0.3,
            pointerEvents:
              hasEmail && hasShipping && hasShippingAddress
                ? "initial"
                : "none",
          }}
        >
          <MobileOnly>
            <Flex sx={{ flexDirection: "column" }}>
              <Text mt={2} sx={{ marginBottom: "0px", paddingBottom: "0px" }}>
                Summary
              </Text>
              <Cart cart={cart} updateLineItem={updateLineItem} />

              <CartTotals
                isShippingSet={!!cart.shipping_methods?.length}
                sx={{
                  borderBottom: "darkGreen",
                  paddingY: "0px",
                  marginY: ["0px", "60px"],
                  marginBottom: ["30px"],
                  paddingBottom: "20px",
                  fontSize: "10px",
                  ".subtotal, .total, .discounts, .shipping": {
                    height: "40px",
                    marginY: "0px",
                    alignItems: "center",
                  },
                }}
              />
            </Flex>
          </MobileOnly>
          <Flex sx={{ flexDirection: "column" }}>
            <Text
              mt={[0, 3]}
              sx={{ marginBottom: "0px", paddingBottom: "0px" }}
            >
              Payment
            </Text>
            <Text
              mb={[2]}
              sx={{ marginTop: "0px", paddingTop: "0px", fontSize: [0] }}
            >
              All transactions are secure and encrypted
            </Text>
          </Flex>
          <PaymentStep
            onPaymentCompleted={handlePaymentCompleted}
            canPay={canPay}
            setProcessing={setProcessing}
            processing={processing}
          />
        </Box>
      </StepContainer>
    </>
  )

  return (
    <Flex
      sx={{
        flexDirection: "row",
        width: "100%",
        height: "calc(100vh - 70px)",
      }}
    >
      <Container
        sx={{
          flexBasis: ["100%", "60%", "70%"],
          maxHeight: "calc(100vh - 70px)",

          overflowY: "scroll",
        }}
        pt={["20px", "25px"]}
        px={[2, "50px", "125px"]}
      >
        {cart.id ? (
          <>{handleStepContainer()}</>
        ) : (
          <Flex
            sx={{ width: "100%", justifyContent: "center", height: "50px" }}
          >
            <Box>
              <Spinner dark />
            </Box>
          </Flex>
        )}
      </Container>
      {width > 767 && <CartDrawer isCheckout={true} />}
    </Flex>
  )
}

export default Checkout
