import styled from "@emotion/styled"
import React, { useState } from "react"
import { Flex, Image } from "theme-ui"
import Pin from "../../assets/images/location.png"
import Spinner from "../base/spinner"
import Text from "../base/text/text"

const ParcelWidgetContainer = styled.div`
  position: relative;
  padding-top: ${(props) => props.theme.space[2]}px;

  span {
    padding-top: 0px;
  }
`

const Address = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ParcelWidget = ({ loading, options, onParcelShopSelected }) => {
  const [selected, setSelected] = useState(null)

  const handleParcelShopSelect = (option, index) => {
    if (onParcelShopSelected) {
      onParcelShopSelected(option)
    }
    setSelected(index)
  }

  return (
    <ParcelWidgetContainer>
      {loading ? (
        <Flex
          sx={{
            width: "100%",
            height: "50px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner dark />
        </Flex>
      ) : (
        <>
          {options.map((option, i) => (
            <Flex
              key={i}
              py={1}
              sx={{
                borderTop: "1px solid lightgrey",
                paddingX: "16px",
                alignItems: "center",
                backgroundColor: selected === i ? "#24543720" : "inherit",
              }}
              onClick={(e) => {
                e.stopPropagation()
                handleParcelShopSelect(option, i)
              }}
            >
              <Flex sx={{ paddingRight: "24px" }}>
                <Image src={Pin} />
              </Flex>
              <Flex sx={{ flexDirection: "column" }}>
                <Text sx={{ fontSize: [0, 1] }}>{option.name}</Text>
                <Address sx={{ fontSize: [0, 1] }}>
                  {option.address_1}, {option.zip} {option.city}
                </Address>
              </Flex>
            </Flex>
          ))}
        </>
      )}
    </ParcelWidgetContainer>
  )
}

export default ParcelWidget
