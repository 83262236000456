import React, { useEffect, useState } from "react"
import caStates from "../../util/states/ca-states.js"
import states from "../../util/states/us-states.js"
import Dropdown from "../dropdown"

const StateSelect = ({
  name,
  onStateSelected,
  value,
  country,
  sx,
  ...rest
}) => {
  const [options, setOptions] = useState([])

  const handleStateSelected = (state) => {
    if (onStateSelected) {
      onStateSelected(state.value)
    }
  }

  useEffect(() => {
    if (country) {
      if (country.toUpperCase() === "CANADA") {
        setOptions(caStates)
      } else if (country.toUpperCase() === "UNITED STATES") {
        setOptions(states)
      }
    }
  }, [country])

  const selected = options.find((o) => o.value === value)

  return (
    <Dropdown
      sx={{
        minWidth: "unset",
        ".select-value": {
          borderColor: "darkGreen",
          border: "none !important",
          borderBottom: "1px solid #245437 !important",
        },
        bg: "background",
        ...sx,
      }}
      name={name}
      value={value}
      valueRender={() => selected?.label}
      valuePlaceholder={"Select State"}
      options={options.map((o) => ({
        value: o.value,
        label: o.label,
        details: {},
      }))}
      onOptionClick={handleStateSelected}
      {...rest}
    />
  )
}

export default StateSelect
