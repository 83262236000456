import styled from "@emotion/styled"
import React from "react"
import StripePayment from "./stripe-payment"

const PayContainer = styled.div`
  .StripeElement {
    height: 48px;
    padding: 10px 12px;
    padding-left: 16px;
    padding-top: 14px;
    border: 1px solid ${(props) => props.theme.colors.darkGreen};
    background-color: ${(props) => props.theme.colors.background};
  }
  .StripeElement--focus {
    box-shadow: 0px 0px 0px 2px rgba(54, 23, 45, 0.1);
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }
`

const PayCards = ({
  children,
  cart,
  sessions,
  setProcessing,
  onPaymentSelected,
  onCanPay,
  onPaymentCompleted,
  onPaymentMethodCreated,
  setCartInventoryCorrection,
}) => {
  const elements = sessions.map((s) => {
    switch (s.provider_id) {
      case "stripe":
        return (
          <StripePayment
            key={s.provider_id}
            cart={cart}
            session={s}
            setProcessing={setProcessing}
            onCanPay={onCanPay}
            onPaymentSelected={() => onPaymentSelected("stripe")}
            onPaymentMethodCreated={onPaymentMethodCreated}
            onPaymentCompleted={onPaymentCompleted}
            setCartInventoryCorrection={setCartInventoryCorrection}
          >
            {children}
          </StripePayment>
        )
      default:
        return null
    }
  })

  if (elements.length) {
    return <PayContainer>{elements}</PayContainer>
  }

  return null
}

export default PayCards
