import styled from "@emotion/styled"
import React, { useEffect, useState } from "react"
import Text from "../base/text/text"
import StripePaymentButtons from "./pay-buttons"
import PayPalCheckout from "./paypal-button"

const Divider = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 20px;
  padding-top: 20px;
`

const Container = styled.div`
  display: flex;
  width: 100%;

  & > * {
    margin-left: 20px;
  }
  & > *:first-of-type {
    margin-left: 0;
  }
`

const Hair = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  &:first-of-type {
    padding-right: 10px;
  }
  &:last-of-type {
    padding-left: 10px;
  }

  hr {
    width: 100%;
    margin: 0;
  }
`

const PaymentProviders = {
  STRIPE: "stripe",
  PAYPAL: "paypal",
}

const PayButtons = ({
  cart,
  sessions,
  setProcessing,
  onCanPay,
  onPaymentSelected,
  onPaymentCompleted,
  onPaymentMethodCreated,
}) => {
  const [canRender, setCanRender] = useState(false)
  const [prepared, setPrepared] = useState({})

  useEffect(() => {
    Promise.all(
      sessions.map(async (s) => {
        switch (s.provider_id) {
          case PaymentProviders.STRIPE: {
            const prep = await StripePaymentButtons.beforeRender(
              cart,
              s,
              prepared[PaymentProviders.STRIPE]
            )
            setPrepared((p) => ({
              ...p,
              [PaymentProviders.STRIPE]: prep,
            }))

            return prep
          }

          case PaymentProviders.PAYPAL:
            return true

          default:
            return null
        }
      })
    ).then((res) => {
      setCanRender(res.some(Boolean))
    })
  }, [sessions])

  const sorted = [...sessions].sort((a, b) =>
    a.provider_id.localeCompare(b.provider_id)
  )

  if (canRender) {
    return (
      <>
        <Container>
          {sorted.map((s) => {
            switch (s.provider_id) {
              case PaymentProviders.STRIPE:
                return (
                  <StripePaymentButtons
                    key={PaymentProviders.STRIPE}
                    cart={cart}
                    session={s}
                    setProcessing={setProcessing}
                    paymentRequest={prepared[PaymentProviders.STRIPE]}
                    onCanPay={onCanPay}
                    onPaymentMethodCreated={onPaymentMethodCreated}
                    onPaymentCompleted={onPaymentCompleted}
                    onCheckout={() =>
                      onPaymentSelected(PaymentProviders.STRIPE)
                    }
                  />
                )
              case PaymentProviders.PAYPAL:
                return (
                  <PayPalCheckout
                    key={PaymentProviders.PAYPAL}
                    cart={cart}
                    session={s}
                    setProcessing={setProcessing}
                    onPaymentCompleted={onPaymentCompleted}
                    onCheckout={() =>
                      onPaymentSelected(PaymentProviders.PAYPAL)
                    }
                  />
                )
              default:
                return null
            }
          })}
        </Container>
        <Divider>
          <Hair>
            <hr />
          </Hair>
          <Text>or pay with credit card</Text>
          <Hair>
            <hr />
          </Hair>
        </Divider>
      </>
    )
  }

  return null
}

export default PayButtons
