import styled from "@emotion/styled"
import { PaymentRequestButtonElement, useStripe } from "@stripe/react-stripe-js"
import React, { useEffect, useState } from "react"
import getStripe from "../../util/stripe"

const CheckoutForm = styled.form`
  margin-bottom: 0px;
  width: 100%;

  .StripeElement {
    height: 40px;
    border-radius: 0px;
  }
  .StripeElement--focus {
    box-shadow: 0px 0px 0px 2px rgba(54, 23, 45, 0.1);
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }
`

const Error = styled.div`
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.danger};
`

const StripePaymentButtons = ({
  session,
  children,
  cart,
  paymentRequest,
  setProcessing,
  onPaymentCompleted,
}) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const stripe = useStripe()

  const handlePrMethod = async (e) => {
    setProcessing(true)

    const { client_secret } = session.data
    // const email = cart.email
    // const address = cart.shipping_address

    const { paymentIntent, error: confirmError } =
      await stripe.confirmCardPayment(
        client_secret,
        { payment_method: e.paymentMethod.id },
        { handleActions: false }
      )

    if (confirmError) {
      e.complete("fail")
    } else {
      e.complete("success")
      if (paymentIntent.status === "requires_action") {
        const { error } = await stripe.confirmCardPayment(client_secret)
        if (error) {
          setErrorMessage(error.message)
        } else {
          onPaymentCompleted(paymentIntent)
        }
      } else {
        onPaymentCompleted(paymentIntent)
      }
    }
    setProcessing(false)
  }

  const handleButtonClick = async () => {
    paymentRequest.update({
      total: {
        label: "Total",
        amount: cart.total,
      },
    })
  }

  useEffect(() => {
    if (stripe && paymentRequest) {
      paymentRequest.on("paymentmethod", handlePrMethod)
    }
  }, [stripe, paymentRequest])

  const options = {
    paymentRequest,
    style: {
      type: "default",
      // One of 'default', 'book', 'buy', or 'donate'
      // Defaults to 'default'

      theme: "dark",
      // One of 'dark', 'light', or 'light-outline'
      // Defaults to 'dark'

      height: "40px",
      // Defaults to '40px'. The width is always '100%'.
    },
  }

  if (!paymentRequest) {
    return null
  }

  return (
    <CheckoutForm>
      {errorMessage && <Error>{errorMessage}</Error>}
      {paymentRequest && (
        <PaymentRequestButtonElement
          onClick={handleButtonClick}
          options={options}
        />
      )}
      {children}
    </CheckoutForm>
  )
}

StripePaymentButtons.beforeRender = async (cart, s, prepared) => {
  const stripe = await getStripe()

  if (prepared) {
    return prepared.canMakePayment().then((res) => {
      return res ? prepared : false
    })
  }

  try {
    const pr = stripe.paymentRequest({
      country: cart.shipping_address.country_code.toUpperCase(),
      currency: cart.currency_code,
      total: {
        label: "Total",
        amount: cart.total,
      },
    })

    return pr.canMakePayment().then((res) => {
      return res ? pr : false
    })
  } catch {
    return false
  }
}

export default StripePaymentButtons
