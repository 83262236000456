import styled from "@emotion/styled"
import { isEmpty } from "lodash"
import React, { useCallback, useEffect, useState } from "react"
import { Box, Flex, Radio } from "theme-ui"
import medusaRequest from "../../services/request"
import { formatShipping } from "../../util/prices"
import Spinner from "../base/spinner"
import Text from "../base/text/text"
import { useMedusaCheckout } from "../medusa-checkout-builder"
import ParcelWidget from "./parcel-widget"

const StepContainer = styled(Flex)`
  width: 100%;

  ${(props) => props.theme.bp.desktop} {
    max-width: 1200px;
  }
`

const ShippingOptionsWrapper = styled(Flex)`
  border: ${(props) => props.theme.borders.darkGreen};
`

export const StyledRadio = styled(Radio)`
  color: ${(props) => props.theme.colors.darkGreen};
`

const ShippingOptionContainer = styled(Flex)`
  border-bottom: 1px solid
    ${(props) =>
      props.removeBorder ? "transparent" : props.theme.colors.darkGreen};

  width: 100%;
  flex-direction: column;

  cursor: pointer;

  span {
    padding-top: 3px;
  }
`

const ShippingStep = ({
  handleDeliverySubmit,
  savedMethods,
  isProcessing,
  settingShipping,
  cart,
}) => {
  const [error, setError] = useState()
  const [isParcelLoading, setParcelLoading] = useState(false)
  const [shippingOptions, setShippingOptions] = useState([])
  const [selectedOptions, setSelectedOptions] = useState({})
  const [parcelShopOptions, setParcelShopOptions] = useState([])
  const [enabled, setEnabled] = useState(
    cart.shipping_address.postal_code && cart.shipping_address.city
  )

  const { getShippingOptions, hasShippingAddress, hasEmail } =
    useMedusaCheckout()

  /**
   * Loads the available parcel shops
   */
  const loadParcelShops = useCallback(
    async (fromOption, postal = null, shippingOpts) => {
      setParcelLoading(true)
      const address = cart.address || cart.shipping_address
      const q = []
      q.push(`postal_code=${postal || address.postal_code}`)
      q.push(`street=${address.address_1}`)
      q.push(`country_code=${address.country_code.toUpperCase()}`)
      return medusaRequest(
        "GET",
        `/webshipper/drop-points/${fromOption.data.webshipper_id}?${q.join(
          "&"
        )}`
      )
        .then(({ data }) => {
          setParcelLoading(false)
          setParcelShopOptions(
            data.drop_points.map((s) => {
              s.id = s.drop_point_id
              return s
            })
          )

          // If no drop points were found, select first standard shipping option
          if (!data?.drop_points?.length) {
            const [optsEntries] = Object.entries(shippingOpts)
            const [profileId, opts] = optsEntries
            const firstStandardSO = opts.find(
              (so) => !so?.data.require_drop_point
            )

            handleSelectOption(profileId, firstStandardSO)
          }
        })
        .catch((err) => console.error(err))
    },
    [cart.address, cart.shipping_address]
  )

  /**
   * If the customer has already saved their selected
   */
  useEffect(() => {
    if (
      savedMethods.length > 0 &&
      cart?.items.every((item) => item.has_shipping)
    ) {
      for (const method of savedMethods) {
        setSelectedOptions((prevState) => ({
          ...prevState,
          [method.shipping_option.profile_id]: method.shipping_option,
        }))
      }

      const packShop = savedMethods.find(
        (s) => s.data && s.data.require_drop_point
      )

      if (packShop) {
        loadParcelShops(packShop.shipping_option)
      }
    }
  }, [savedMethods, setSelectedOptions, loadParcelShops])

  useEffect(() => {
    if (!cart?.items.every((item) => item.has_shipping)) {
      setSelectedOptions({})
    }
  }, [setSelectedOptions, cart.items])
  /**
   * On load we want to fetch all the shipping options available for the cart.
   * Furthermore, we preselect shipping options if only one exists.
   */
  useEffect(() => {
    // Wait until the customer has entered their address information
    if (!cart.shipping_address?.country_code) {
      return
    }

    getShippingOptions().then((partitioned) => {
      if (isEmpty(partitioned)) {
        setError("No shipping options")
      }

      setShippingOptions(partitioned)
      setEnabled(
        cart.shipping_address.postal_code && cart.shipping_address.city
      )
    })
  }, [cart.shipping_address.postal_code])

  useEffect(() => {
    // Wait until the customer has entered their address information
    getShippingOptions().then((partitioned) => {
      if (isEmpty(partitioned)) {
        setError("No shipping options")
      }

      setShippingOptions(partitioned)
      setEnabled(
        cart.shipping_address.postal_code && cart.shipping_address.city
      )
    })
  }, [cart.subtotal])

  const handleParcelShopSelected = (selectedOption, value) => {
    const newSelected = {
      ...selectedOption,
      data: {
        ...selectedOption.data,
        drop_point_id: value.drop_point_id,
        drop_point_name: value.name,
        drop_point_address_1: value.address_1,
        drop_point_zip: value.zip,
        drop_point_city: value.city,
        drop_point_country_code: value.country_code,
      },
    }

    const newState = {
      ...selectedOptions,
      [selectedOption.profile_id]: newSelected,
    }

    if (handleDeliverySubmit) {
      for (const k of Object.keys(newState)) {
        const selectedOption = newState[k]
        if (selectedOption.data && selectedOption.data.id === "gls-pakkeshop") {
          if (!selectedOption.data.pakkeshop_id) {
            setError("Error fetching parcel shops")
            return
          }
        }
      }
      handleDeliverySubmit(newState)
    }
  }

  const handleSubmit = () => {
    setSelectedOptions((selectedOptions) => {
      if (handleDeliverySubmit && !isEmpty(selectedOptions)) {
        for (const k of Object.keys(selectedOptions)) {
          const selectedOption = selectedOptions[k]
          if (
            selectedOption.data &&
            selectedOption.data.id === "gls-pakkeshop"
          ) {
            if (!selectedOption.data.pakkeshop_id) {
              setError("Error fetching parcel shops")
              return
            }
          }
        }
        handleDeliverySubmit(selectedOptions)
      }

      return selectedOptions
    })
  }

  const handleSelectOption = async (k, o) => {
    setSelectedOptions((previousOptions) => ({
      ...previousOptions,
      [k]: o,
    }))

    if (o.data && o.data.require_drop_point) {
      await loadParcelShops(o, null, shippingOptions)
    } else {
      await handleSubmit()
    }
  }

  const isLoading = isEmpty(shippingOptions) || isProcessing

  return (
    <Box
      mt={3}
      sx={{
        flex: 1,
        opacity:
          enabled && hasShippingAddress && hasEmail && !isLoading ? 1 : 0.3,
        position: "relative",
      }}
    >
      <Text>Choose a shipping option</Text>
      {settingShipping && (
        <Flex
          sx={{
            width: "100%",
            height: "50px",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "absolute",
            mt: 4,
          }}
        >
          {error ? (
            <Flex sx={{ flexDirection: "column", textAlign: "center", mt: 2 }}>
              <Text>{error}</Text>
              <Text>
                Looks like we're missing some information. Please return to Step
                1 and double-check that your inserted address, postal code and
                city is correct.
              </Text>
            </Flex>
          ) : (
            <Spinner dark={true} />
          )}
        </Flex>
      )}
      <ShippingOptionsWrapper
        mt={2}
        sx={{ flexDirection: "column", opacity: isLoading ? 0.3 : 1 }}
      >
        {Object.keys(shippingOptions).map((k) => {
          const options = shippingOptions[k]

          const selectedOption = selectedOptions[k] || {}

          return options
            .sort((a, b) => a.amount - b.amount)
            .map((o, i) => {
              return (
                <ShippingOptionContainer
                  key={i}
                  onClick={() => (enabled ? handleSelectOption(k, o) : {})}
                  sx={{ fontSize: [1, 2, 2], paddingTop: "0px" }}
                  removeBorder={i === options.length - 1}
                >
                  <Flex
                    sx={{
                      padding: "16px",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Flex>
                      <StyledRadio
                        checked={o.id === selectedOption.id}
                        readOnly={true}
                      />
                      <Text sx={{ height: "100%" }}>{o.name}</Text>
                    </Flex>
                    <Text>{formatShipping(o.amount, cart)} </Text>
                  </Flex>
                  {o.data &&
                    o.data.require_drop_point &&
                    o.id === selectedOption.id && (
                      <Text sx={{ fontSize: "12px", paddingX: "16px" }}>
                        Select a pick-up location
                      </Text>
                    )}
                  {o.data &&
                    o.data.require_drop_point &&
                    o.id === selectedOption.id && (
                      <ParcelWidget
                        loading={isParcelLoading}
                        options={parcelShopOptions}
                        value={selectedOption.data}
                        onParcelShopSelected={(value) =>
                          enabled ? handleParcelShopSelected(o, value) : {}
                        }
                      />
                    )}
                </ShippingOptionContainer>
              )
            })
        })}
      </ShippingOptionsWrapper>
    </Box>
  )
}

export default ShippingStep
