import styled from "@emotion/styled"
import { PayPalButtons } from "@paypal/react-paypal-js"
import React from "react"
import Medusa from "../../services/api"

const PaypalCheckoutButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 100%;

  max-height: 40px;

  & > div {
    max-width: 750px;
    width: 100%;
  }

  .paypal-buttons {
    width: 100%;
    max-width: unset !important;
    height: 40px !important;
  }
`

const PayPalCheckout = ({
  session,
  setProcessing,
  onPaymentCompleted,
  onCheckout,
}) => {
  const handleApprove = async (_, actions) => {
    setProcessing(true)

    await onCheckout().catch((err) => setProcessing(false))

    actions.order
      .authorize()
      .then(() => {
        onPaymentCompleted()
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  return (
    <PaypalCheckoutButton>
      <PayPalButtons
        style={{
          height: 40,
          color: "black",
        }}
        onApprove={handleApprove}
        createOrder={async () => {
          await Medusa.cart.refreshPaymentSession(session?.cart_id, "paypal")
          const res = await Medusa.cart.setPaymentSession(session?.cart_id, "paypal")
          return res?.data?.cart?.payment_session?.data?.id
        }
        }
      ></PayPalButtons>
    </PaypalCheckoutButton>
  )
}

PayPalCheckout.beforeRender = () => {
  return true
}

export default PayPalCheckout
