import styled from "@emotion/styled"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import React, { useState } from "react"

const CheckoutForm = styled.form`
  .StripeElement {
    height: 48px;
    padding: 10px 12px;
    padding-left: 16px;
    padding-top: 14px;
    border: 1px solid ${(props) => props.theme.colors.darkGreen};
    background-color: ${(props) => props.theme.colors.background};
  }
  .StripeElement--focus {
    box-shadow: 0px 0px 0px 2px rgba(54, 23, 45, 0.1);
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }
`

const Error = styled.div`
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.danger};
`

const StripePayment = ({
  session,
  disabled = false,
  children,
  cart,
  setProcessing,
  onPaymentCompleted,
  onCanPay,
}) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const stripe = useStripe()
  const elements = useElements()

  const handleChange = async (e) => {
    if (onCanPay) {
      onCanPay(e.complete)
    }
  }

  const handlePayment = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setProcessing(true)

    const { client_secret } = session.data
    const email = cart.email
    const address = cart.shipping_address

    return stripe
      .confirmCardPayment(client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: address.fullName,
            email: email,
            phone: address.phone,
            address: {
              city: address.city,
              country: address.country,
              line1: address.line1,
              line2: address.line2,
              postal_code: address.postal,
            },
          },
        },
      })
      .then(({ error, paymentIntent }) => {
        if (error) {
          const pi = error.payment_intent

          if (
            (pi && pi.status === "requires_capture") ||
            (pi && pi.status === "succeeded")
          ) {
            return onPaymentCompleted()
          }

          setErrorMessage(error.message)
          return
        }

        if (
          (paymentIntent && paymentIntent.status === "requires_capture") ||
          paymentIntent.status === "succeeded"
        ) {
          return onPaymentCompleted()
        }

        return
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  return (
    <CheckoutForm onSubmit={handlePayment}>
      {errorMessage && <Error>{errorMessage}</Error>}
      <CardElement
        onChange={handleChange}
        options={{ disabled }}
        style={{
          base: {
            color: "#245437",
            backgroundColor: "#e7e5e1",
            fontSize: "16px",
            fontSmoothing: "antialiased",
          },
        }}
      />
      {children}
    </CheckoutForm>
  )
}

export default StripePayment
