import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import { Elements } from "@stripe/react-stripe-js"
import React, { useEffect } from "react"
import { Box, Button, Flex } from "theme-ui"
import getStripe from "../../util/stripe"
import Spinner from "../base/spinner"
import { useMedusaCheckout } from "../medusa-checkout-builder"
import PayCards from "./pay-cards"
import PayButtons from "./payment-buttons"

const PAYPAL_CLIENT_ID =
  process.env.GATSBY_PAYPAL_CLIENT_ID ||
  "ATsQfeVy5Wgt3ctqUlCS08Qk3MDWpPzN01C6R3oT1gY2z-Q1qsaBLzMC_N5wBhpOLN4NPsBLv1irKwiV"

const PAYPAL_INTENT = process.env.PAYPAL_INTENT || "authorize"

const PaymentStep = ({
  onPaymentCompleted,
  canPay,
  setCanPay,
  processing,
  setProcessing,
}) => {
  const {
    cart,
    setPaymentSession,
    updatePaymentSession,
    processingPayment,
    initializePaymentSessions,
  } = useMedusaCheckout()

  useEffect(() => {
    if (cart.payment_sessions?.length && !cart.payment_session) {
      setPaymentSession("stripe")
    }
  }, [cart.payment_sessions])

  const createPaymentSessions = async () => {
    setProcessing(true)
    await initializePaymentSessions(cart.id)
    setProcessing(false)
  }

  useEffect(() => {
    if (canPay && cart.payment_sessions && cart.payment_sessions.length === 0) {
      createPaymentSessions()
    }
  }, [canPay])

  const handleSetPaymentMethod = (method) => {
    return updatePaymentSession(method)
  }

  const handlePaymentCompleted = () => {
    if (onPaymentCompleted) {
      return onPaymentCompleted()
    }
  }

  return (
    <Elements stripe={getStripe()}>
      <PayPalScriptProvider
        options={{
          currency: cart.currency_code.toUpperCase(),
          "disable-funding": "card",
          intent: PAYPAL_INTENT,
          "client-id": PAYPAL_CLIENT_ID,
        }}
      >
        {processing && (
          <Flex
            sx={{ width: "100%", justifyContent: "center", height: "50px" }}
          >
            <Box>
              <Spinner dark />
            </Box>
          </Flex>
        )}
        <PayButtons
          cart={cart}
          sessions={cart.payment_sessions}
          setProcessing={setProcessing}
          onPaymentSelected={setPaymentSession}
          onCanPay={setCanPay}
          onPaymentCompleted={handlePaymentCompleted}
          onPaymentMethodCreated={handleSetPaymentMethod}
        />
        <PayCards
          cart={cart}
          sessions={cart.payment_sessions}
          setProcessing={setProcessing}
          onPaymentSelected={setPaymentSession}
          onCanPay={setCanPay}
          onPaymentCompleted={handlePaymentCompleted}
          onPaymentMethodCreated={handleSetPaymentMethod}
        >
          <Button
            variant="primary"
            sx={{
              height: "48px",
              width: ["100%"],
              marginLeft: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            type="submit"
            my={3}
            disabled={!canPay}
            loading={processingPayment || processing}
          >
            Submit order
          </Button>
        </PayCards>
      </PayPalScriptProvider>
    </Elements>
  )
}

export default PaymentStep
