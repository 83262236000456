import styled from "@emotion/styled"
import React, { useContext } from "react"
import { Flex, Text } from "theme-ui"
import {
  formatCartShippingTotal,
  formatCartSubtotal,
  formatCartTotal,
} from "../../util/prices"
import { DesktopOnly } from "../base"
import { HorizontalHairline } from "../base/hairline"
import { MedusaCheckoutContext } from "../medusa-checkout-builder"

const TotalsContainer = styled(Flex)`
  min-height: 100px;

  flex-direction: column;

  div {
    justify-content: space-between;
  }
`

const CartTotals = ({ isShippingSet, sx, order }) => {
  const isOrder = !!order

  let { cart } = useContext(MedusaCheckoutContext)

  const taxRate = isOrder ? order?.region.tax_rate : cart.tax_rate

  // if an order is provided, we use that to show totals
  if (order) {
    cart = order
    cart.taxRate = taxRate
  }

  return (
    <TotalsContainer py={3} sx={{ ...sx, ">div": { marginBottom: 1 } }}>
      <Flex className="subtotal">
        <Text mr={2}>
          Subtotal {parseInt(cart.taxRate) === 0 ? "" : "(incl. VAT) "}
        </Text>
        <Text>{formatCartSubtotal(cart)}</Text>
      </Flex>
      {isShippingSet && (
        <Flex className="shipping">
          <Text mr={2}>Shipping </Text>
          <Text>{formatCartShippingTotal(cart)}</Text>
        </Flex>
      )}
      {cart.discounts?.length ? (
        <Flex className="discounts">
          <Text>Discounts </Text>
        </Flex>
      ) : null}
      <DesktopOnly>
        <HorizontalHairline />
      </DesktopOnly>
      <Flex className="total">
        <Text mr={2}>Total </Text>
        <Text>{formatCartTotal(cart)}</Text>
      </Flex>
    </TotalsContainer>
  )
}

export default CartTotals
