import Medusa from "../services/api"

const itemsMap = (items) => {
  const map = {}

  for (const item of items) {
    const { variant } = item

    if (map[variant.id]) {
      map[variant.id] += item.quantity
    } else {
      map[variant.id] = item.quantity
    }
  }

  return map
}

const checkInventory = async (cart) => {
  const variantIds = cart.items.map(({ variant }) => {
    if (variant && variant.id) {
      return variant.id
    }
  })

  if (!variantIds.length) {
    return { cart, didUpdate: false }
  }

  const variantQuantities = await Medusa.variants
    .list({ ids: variantIds })
    .then(({ data }) => data.variants)

  const itemsWithVariants = cart.items.filter(
    (el) => el.variant && el.variant.id
  )

  const lineItemQuantities = itemsMap(itemsWithVariants)

  const bundlesToDelete = []
  const itemsToDelete = []
  const itemsToReduce = []

  for (const item of itemsWithVariants) {
    // Skip stock correction, if item is a return line
    if (item.metadata?.is_return_line) {
      continue
    }

    const variant = item.variant.id

    const liveVariant = variantQuantities.find((v) => v.id === variant)
    const stock = liveVariant.inventory_quantity
    const allowBackorder = liveVariant.allow_backorder

    // If we are completely out of stock, we remove it
    // else we adjust the line item quantity
    if (stock === 0 && !allowBackorder) {
      // If an update was made, we ensure, that bundles are removed entirely
      if (item.metadata?.bundle_id) {
        bundlesToDelete.push({
          id: item.metadata.bundle_id,
        })
      } else {
        itemsToDelete.push({
          id: item.id,
        })
      }
    }

    if (lineItemQuantities[variant] > stock && stock > 0) {
      // If an update was made, we ensure, that bundles are removed entirely
      if (item.metadata?.bundle_id) {
        bundlesToDelete.push({
          id: item.metadata.bundle_id,
        })
      } else {
        itemsToReduce.push({
          id: item.id,
          quantity: stock,
        })
      }
    }
  }

  const didUpdate =
    !!bundlesToDelete.length || !!itemsToDelete.length || !!itemsToReduce.length
  return { cart, didUpdate, bundlesToDelete, itemsToDelete, itemsToReduce }
}

export default checkInventory
